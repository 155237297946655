import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import Moregames from "../Grid/more-games";
import Sidebar from "../Grid/sidebar";
import { useParams } from "react-router-dom";
import whatsapp from "../../Assets/whatsapp.png";

// Custom hook for counting time in minutes and seconds

function Playerzone() {
  const { id } = useParams();
  const [coins, setCoins] = useState(0);
  const [timer, setTimer] = useState(0);

  let oldReward = Number(localStorage.getItem("ads_reward"));

 
  useEffect(() => {
    // Function to update coins every minute
    const updateCoins = () => {
      setCoins((prevCoins) => prevCoins + 10);
      let oldcoins = localStorage.getItem("coins");
      localStorage.setItem("coins", Number(oldcoins) + 10);
    };

    // Function to update timer every second
    const updateTimer = () => {
      setTimer((prevTimer) => prevTimer + 1);
    };

    // Start the timer when component mounts
    const coinsTimer = setInterval(updateCoins, 60000); // 60000 milliseconds = 1 minute
    const secondsTimer = setInterval(updateTimer, 1000); // 1000 milliseconds = 1 second

    // Clean up the timers when component unmounts
    return () => {
      clearInterval(coinsTimer);
      clearInterval(secondsTimer);
    };
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  let mygamelist = [
    {
      id: 1,
      name: "Slider",
      thumb: "https://quizzigo.com/games/slider/thumb_2.png",
      url: "https://quizzigo.com/games/slider/",
      desc: "Slider",
    },
    {
      id: 2,
      name: "Puzzle Color",
      thumb: "https://quizzigo.com/games/puzzle-color/thumb_2.png",
      url: "https://quizzigo.com/games/puzzle-color/",
    },
    {
      id: 3,
      name: "Fill Dot",
      thumb: "https://quizzigo.com/games/fill-dotted-/thumb_2.png",
      url: "https://quizzigo.com/games/fill-dotted-",
    },
    {
      id: 4,
      name: "Candy Blocks",
      thumb: "https://quizzigo.com/games/candy-blocks/thumb_2.jpg",
      url: "https://quizzigo.com/games/candy-blocks/",
    },
    {
      id: 5,
      name: "Draw Master",
      thumb: "https://quizzigo.com/games/drawmaster/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/drawmaster/index.html",
    },
    {
      id: 6,
      name: "Pattern String",
      thumb: "https://quizzigo.com/games/pattern-strings/thumb_2.jpg",
      url: "https://quizzigo.com/games/pattern-strings",
    },
    {
      id: 7,
      name: "Paper Fold",
      thumb: "https://quizzigo.com/games/paper-fold/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/paper-fold/index.html",
    },
    {
      id: 8,
      name: "House Paint",
      thumb: "https://quizzigo.com/games/house-paint/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/house-paint/index.html",
    },
    {
      id: 9,
      name: "Basket Ball",
      thumb: "https://quizzigo.com/games/basketball-challenge/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/basketball-challenge/index.html",
    },
    {
      id: 10,
      name: "Draw Master",
      thumb: "https://quizzigo.com/games/drawmaster/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/drawmaster/index.html",
    },
    {
      id: 11,
      name: "Pixel Slide",
      thumb: "https://quizzigo.com/games/pixel-slilde/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/pixel-slilde/index.html",
    },
    {
      id: 12,
      name: "Troll Thief",
      thumb: "https://quizzigo.com/games/troll-thief/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/troll-thief/index.html",
    },
    {
      id: 13,
      name: "Toilet Rush",
      thumb: "https://quizzigo.com/games/toilet-race/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/toilet-race/index.html",
    },
    {
      id: 14,
      name: "Roll Ball",
      thumb: "https://quizzigo.com/games/roll-the-ball/thumb_2.jpg",
      url: "https://quizzigo.com/games/roll-the-ball",
    },
    {
      id: 15,
      name: "Onet 3D",
      thumb: "https://quizzigo.com/games/onet-3d/thumb_2.jpg",
      url: "https://quizzigo.com/games/onet-3d",
    },
    {
      id: 16,
      name: "Grab Pack",
      thumb: "https://quizzigo.com/games/grab-pack/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/grab-pack/index.html",
    },
    {
      id: 17,
      name: "Poly Art",
      thumb: "https://quizzigo.com/games/poly-art/thumb_2.jpg",
      url: "https://quizzigo.com/games/poly-art",
    },
    {
      id: 18,
      name: "Free Bird",
      thumb: "https://quizzigo.com/games/poly-art/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/free-bird/index.html",
    },
    {
      id: 19,
      name: "Monster Go",
      thumb: "https://quizzigo.com/games/monster-go/thumb_2.jpg",
      url: "https://quizzigo.com/games/monster-go",
    },
    {
      id: 20,
      name: "Fuit Master",
      thumb: "https://quizzigo.com/games/fruit-master/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/FruitMaster/index.html",
    },
    {
      id: 21,
      name: "Flip Bottle",
      thumb: "https://quizzigo.com/games/flip-bottle/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/bottle-flip/index.html",
    },
    {
      id: 22,
      name: "Fall Beans",
      thumb: "https://quizzigo.com/games/fall-beans/thumb_2.png",
      url: "https://quizzigo.com/games/fall-beans",
    },
    {
      id: 23,
      name: "2k Shoot",
      thumb: "https://quizzigo.com/games/2k-shoot/thumb_2.png",
      url: "https://quizzigo.com/games/2k-shoot",
    },
    {
      id: 24,
      name: "Match Shapes",
      thumb: "https://quizzigo.com/games/match-shapes/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/match-shapes/index.html",
    },
    {
      id: 25,
      name: "Save The Dog",
      thumb: "https://quizzigo.com/games/save-the-dog/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/save-the-dog/index.html",
    },
    {
      id: 26,
      name: "Theeth Cleaner",
      thumb: "https://quizzigo.com/games/teeth-runner/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/teeth-runner/index.html",
    },
    {
      id: 27,
      name: "Cricket Champion",
      thumb: "https://quizzigo.com/games/Cricket/512.png",
      url: "https://quizzigo.com/games/Cricket/",
    },
    {
      id: 28,
      name: "Jewel Classic",
      thumb: "https://quizzigo.com/games/jewel-classic/thumb_2.jpg",
      url: "https://quizzigo.com/games/jewel-classic",
    },
    {
      id: 29,
      name: "Match Pair",
      thumb: "https://quizzigo.com/games/match-pair/thumb_2.jpg",
      url: "https://quizzigo.com/games/match-pair",
    },
  ];

  const gamedata = mygamelist.find((game) => game.id === Number(id));
  const gameurl = window.location.href;
  const iframeRef = useRef(null);

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        console.log({ adsbygoogle });
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <section>
      <Container fluid className="" id="home">
        <Container className="home-content">
          <div className="row">
            <div className="col-md-6" style={{ paddingTop: "1%" }}>
              <div className="row row-cols-1 mb-3">
                <div className="col d-flex justify-content-center bg-dark frame-box">
                  <iframe
                    ref={iframeRef}
                    title="game"
                    className="game-iframe p-3"
                    id="game-area"
                    src={gamedata.url}
                    width="720"
                    height="500"
                  ></iframe>
                </div>

                {/*  <div className="col like-dislike-bar pt-2 bg-dark">
                  <div className="row row-cols-3">
                    <div className="col-3 like-btn-box d-flex justify-content-center">
                      <div className="w-50">
                        <div className="d-flex justify-content-center">
                          <img
                            src={gamedata.thumb}
                            className="playzone-game-thumb"
                            alt="GameLogo"
                            width={60}
                          />
                        </div>
                        <h6 className="text-center mt-2">{gamedata.name}</h6>
                      </div>
                    </div>
                    
                    <div className="col">
                    <h6>Coins: {coins} ??</h6>
      <h6>Timer: {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}</h6>
                    </div>

                    <div className="col-5 d-flex justify-content-end">
                      <div>
                        <a
                          href={"whatsapp://send?text=" + gameurl}
                          className="d-inline"
                        >
                          <img
                            src={whatsapp}
                            alt="whatsapps"
                            className="share-whatsapp"
                          />
                        </a>
                        <h5 className="text-center">share</h5>
                      </div>
                    </div>
                  </div>
                  <div>
                      <p>Description:  You'll get 10 coins per minute for gameplay</p>
                    </div>
                </div> */}
              </div>
            </div>

            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-3 d-flex justify-content-center mb-3">
              <div style={{ width: "300px", height: "250px" }}>
                {/* Here's Place your Native ad */}
                <ins
                  className="adsbygoogle adslot_1"
                  style={{ display: "block" }}
                  data-ad-client="ca-pub-8026299207830445"
                  data-ad-slot="4327847264"
                ></ins>
              </div>
            </div>
          </div>
          <div>
            <Moregames />
          </div>
        </Container>
      </Container>
    </section>
  );
}

export default Playerzone;
